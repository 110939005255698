* {
  font-family: "Poppins" !important;
}

.ph-seal-check {
  color: #0088be;
}

#about p {
  max-width: 540px;
}

h1 {
  font-size: 3.625rem;
  line-height: 4.25rem;
}

h5 {
  font-size: 2.0625rem;
  line-height: 2.625rem;
}

p {
  font-size: 1.4375rem;
  line-height: 2rem;
}

@keyframes moveInFromBottom {
  0% {
    opacity: 0;
    transform: translateY(60px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes moveOutToBottom {
  0% {
    opacity: 1;
    transform: translateY(0);
  }
  100% {
    opacity: 0;
    transform: translateY(60px);
  }
}

.bi-download {
  -webkit-text-stroke-width: 0.4px;
}

.btn {
  font-size: 16px !important;
  width: 194px;
  padding: 12px 20px;
  border-radius: 6px;
}

.btn-demo {
  font-size: 16px;
  font-weight: 500;
  border: none;
  text-transform: none;
}

.btn-white {
  background-color: white;
  color: #0088be;
  font-weight: 600;
}

.btn-white:hover {
  background-color: white;
  color: #0088be;
  font-weight: 600;
}

.floating {
  position: fixed;
  bottom: 3rem;
  right: 2.5rem;
  display: flex;
  flex-direction: column;
  align-items: end;
  z-index: 10;
}

.floating-blog {
  position: fixed;
  bottom: 3rem;
  right: 1.5rem;
  display: flex;
  flex-direction: column;
  align-items: end;
  z-index: 10;
}

.btn-whatsapp-fade-out,
.btn-whatsapp-fade-out:hover,
.btn-whatsapp-fade-out:active {
  display: block;
  pointer-events: none;
  background-color: #25d366;
  color: white;
  width: auto;
  margin: 6px;
  padding: 12px 16px;
  border-radius: 40px;
  font-size: 20px;
  animation: fade-out 0.3s;
  opacity: 0;
}

@keyframes fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.btn-whatsapp-fade-in,
.btn-whatsapp-fade-in:hover,
.btn-whatsapp-fade-in:active {
  display: block;
  background-color: #25d366;
  color: white;
  width: auto;
  margin: 6px;
  padding: 12px 16px;
  border-radius: 40px;
  font-size: 20px;
  animation: fade-in 0.3s;
  opacity: 1;
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.wa-text {
}

.btn-whatsapp-fade-in:after {
  content: " \00a0 \00a0 Chat kami!";
  max-width: 0;
  transition: max-width 0.75s;
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  vertical-align: middle;
}

.btn-whatsapp-fade-in:hover::after {
  max-width: 200px; /* or any suitable width */
}

.bottom-bar {
  display: none;
}

.scroll-to-top-btn-fade-out {
  display: block;
  pointer-events: none;
  width: 4.5rem;
  height: 4.5rem;
  cursor: pointer;
  transition: fadeIn 0.3s ease-in-out;
  animation: to-top-fade-out 0.3s;
  opacity: 0;
}

@keyframes to-top-fade-out {
  from {
    opacity: 0.8;
  }
  to {
    opacity: 0;
  }
}

.scroll-to-top-btn-fade-in {
  display: block;
  width: 4.5rem;
  height: 4.5rem;
  cursor: pointer;
  transition: fadeIn 0.3s ease-in-out;
  animation: to-top-fade-in 0.3s;
  opacity: 0.8;
}

@keyframes to-top-fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 0.8;
  }
}

.scroll-to-top-btn-fade-in:hover {
  opacity: 1;
  transform: fadeIn 1s ease-in-out;
}

.bottom-bar-fade-in {
  display: none;
}

.bottom-bar-fade-out {
  display: none;
}

header.masthead {
  padding-top: 3rem;
  padding-bottom: calc(10rem - 4.5rem);
  color: white;
  overflow: hidden;
  height: 800px;
}

header.masthead::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 800px;
  background: url("../../public/img/hero-bg.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-size: cover;
  clip-path: polygon(0 0, 100% 0, 100% 20%, 70% 100%, 0 100%);
  z-index: -1; /* Ensure the pseudo-element is behind the content */
}

#mainNav {
  margin: auto;
  margin-top: 24px;
  background-color: #fff;
  border-radius: 20px;
  max-width: 1320px;
  transition: 0.5s;
}

#mainNav.hidden {
  transform: translateY(-130%);
}

.nav-pills .nav-link {
  border-radius: 6px;
  border: 1px solid var(--oml-neutral-40, #e0e0e0);
  background: var(--oml-neutral-10, #fff);

  /* Shadow 1 */
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.12);
}

.nav-pills .nav-link.active {
  border: 1.5px solid var(--ojire, #0088be);
  color: var(--ojire, #0088be) !important;
  font-weight: 600;
  background: white;
  /* Shadow 2 */
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.1);
}

.navbar-brand img {
  height: 3rem;
}

.nav-toggle {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  width: 32px;
  height: 32px;
  background-color: #f5f5f5;
  border-radius: 6px;
  padding: 8px 0;
}

.nav-toggle .bar {
  height: 1px;
  width: 50%;
  background-color: #404040;
  transition: all 200ms ease-in-out;
  border-radius: 8px;
}

.nav-toggle:hover {
  cursor: pointer;
}

.nav-layanan-pointer {
}

.nav-layanan-pointer:hover {
  cursor: pointer;
}

.x:nth-of-type(1) {
  transition: all 200ms ease-in-out;
  transform: rotate(45deg);
  transform-origin: top left;
  width: 28px;
}

.x:nth-of-type(2) {
  transition: all 200ms ease-in-out;
  transform-origin: center;
  width: 0;
}

.x:nth-of-type(3) {
  transition: all 200ms ease-in-out;
  transform: rotate(-45deg);
  transform-origin: bottom left;
  width: 28px;
}

.toggle-switch {
  font-size: 16px;
  position: relative;
  display: inline-block;
  width: 80px;
  height: 40px;
  background-color: #f1f1f1;
  border-radius: 20px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.toggle-switch::before {
  content: "ID";
  position: absolute;
  left: 12.5px;
  top: 50%;
  transform: translateY(-50%);
  color: white;
  font-weight: bold;
  transition: left 0.3s, color 0.3s;
  z-index: 2;
}

.toggle-switch::after {
  content: "EN";
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
  color: #333;
  font-weight: bold;
  z-index: 2;
}

.toggle-switch .toggle-button {
  position: absolute;
  width: 35px;
  height: 35px;
  background-color: #00a7e1;
  border-radius: 50%;
  top: 2.5px;
  left: 2.5px;
  transition: all 0.3s;
  z-index: 1;
}

.toggle-switch.active .toggle-button {
  left: 42.5px;
  background-color: #333;
}

.toggle-switch.active::before {
  left: 12.5px;
  color: #333;
}

.toggle-switch.active::after {
  right: 9.5px;
  color: white;
}

#heroContent {
  margin-top: 70px;
  height: 100%;
}

#heroContent .btn:hover {
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease-in-out;
}

.hero-text p {
  font-size: 1.75rem;
  line-height: 2.25rem;
}

.multi-bg {
  background: url(../../public/img/multi-bg.jpg) no-repeat center center;
  background-size: cover;
}

.multi-bg2 {
  background: url(../../public/img/multi-bg2.jpg) no-repeat;
  background-position: top;
  background-size: cover;
}

.hero-images {
  position: absolute;
  width: 110%;
  max-width: none;
  height: auto;
  right: -15%;
  bottom: 10px;
  transform: translateY(53%);
}

.hero-accesories {
  position: absolute;
  bottom: 1%;
  left: -4%;
  width: 150px;
  z-index: -1;
}

.hero-accesories2 {
  position: absolute;
  bottom: 10px;
  right: -5%;
  width: 193px;
  z-index: -1;
}

.hero-header {
  text-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

.hero-sub-header {
  font-size: 24px;
  text-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 554px;
}

span.header-yellow {
  color: #ffb60d;
}

.icon-section {
  width: 32px;
}

/* Partners */
#partners {
}

#partners h5 {
  padding-bottom: 50px;
  font-size: 2.5rem;
}

.partners-img img {
  max-height: 50px;
}

.partners-img {
  max-width: 1020px;
  display: flex;
  justify-content: space-evenly;
}

.cimb-logo-mobile {
}

.bri-logo-mobile {
}

.bni-logo-mobile {
}

/* Partner end */

/* WHY */
#whyOjire {
  background: url(../../public/img/why-bg.png) no-repeat center center;
  background-size: cover;
  padding-bottom: 9rem;
}

#whyOjire .card {
  width: auto;
  background-color: #fff;
  border-radius: 20px;
}

.card-body img {
  width: 80px;
}

#whyOjire .card-title {
  font-size: 1.5rem;
  font-weight: 600;
}

#whyOjire .card-text {
  font-size: 1.125rem;
  line-height: 1.75rem;
}

@keyframes fillColor {
  from {
    background-size: 100% 0;
  }
  to {
    background-size: 100% 100%;
  }
}

/* Keyframes for image fade-in */
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Keyframes for image fade-out */
@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@keyframes expand {
  from {
    max-height: 0;
    opacity: 0;
  }
  to {
    max-height: 200px;
    opacity: 1;
  } /* Adjust max-height based on your content */
}

@keyframes collapse {
  from {
    max-height: 200px;
    opacity: 1;
  } /* Adjust max-height based on your content */
  to {
    max-height: 0;
    opacity: 0;
  }
}
/*  END OF WHY */

/* FITUR */
section#fiturOjire {
  padding-top: 6rem;
  padding-bottom: calc(10rem - 4.5rem);
  color: white;
  overflow: hidden;
}

section#fiturOjire::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url("../../public/img/fitur-bg.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-size: cover;
  z-index: -1;
}

.feature-accesories {
  position: absolute;
  bottom: -10%;
  left: -3%;
  z-index: -1;
}

.feature-accesories2 {
  position: absolute;
  top: -5%;
  right: -3%;
  z-index: -1;
}

#fiturOjire .card {
  background-color: #fff;
  padding: 0px;
  border-radius: 24px;
  position: relative;
  aspect-ratio: 1/1;
  border: none;
}

.card-brand {
  background-color: transparent !important;
  box-shadow: none;
}

.card-brand img {
  left: 50% !important;
  top: 0;
  transform: translateX(-50%);
}

.card-brand h1 {
  font-size: 6vw;
  font-weight: 600;
  text-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

#fiturOjire .card-body {
  padding: 24px;
  z-index: 1;
}

#fiturOjire .card h5 {
  /* color: #0088be; */
  color: #00aeef;
  font-weight: 600;
  margin-bottom: 8px;
  font-size: 1.75rem;
  line-height: 2.25rem;
}

#fiturOjire .card p {
  /* color: #0088be; */
  color: #00aeef;
  font-weight: 500;
  margin-bottom: auto;
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.aspect-ratio {
  position: relative;
  width: 100%;
  padding-top: 100%; /* 1:1 Aspect Ratio */
}

.card-img-top {
  position: absolute;
  bottom: 0;
  z-index: 0;
}

.aspect-ratio .card-body,
.aspect-ratio .card-img-top {
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
/* END OF FITUR */

/* HOW */
#howContent {
  min-height: 350px;
}

.workflow-step {
  position: relative;
  text-align: center;
  margin: 20px 0;
}

.circle-bg {
  position: absolute;
  top: 13%;
  left: 26%;
  width: 200px;
  height: 200px;
  background: #00abe932; /* Adjust color and opacity */
  border-radius: 50%;
  filter: blur(20px);
  transform: translate(-50%, -50%);
  z-index: -1;
}

.circle-green {
  position: absolute;
  bottom: 5%;
  right: -28%;
  width: 200px;
  height: 200px;
  background: #d4fa3d3d; /* Adjust color and opacity */
  border-radius: 50%;
  filter: blur(20px);
  transform: translate(-50%, -50%);
  z-index: -1;
}

.step-number {
  position: absolute;
  top: -60px; /* Adjust based on the required position */
  left: 50%;
  transform: translateX(-50%);
  background-color: #a4b952;
  color: #fff;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  font-weight: bold;
  z-index: 1;
}

.step {
  position: relative;
  text-align: center;
  margin: 20px 0;
  padding: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.step.active {
  background: #ffffff;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
}

.number {
  color: #fff;
  width: 40px !important;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  font-weight: bold;
  z-index: 1;
  flex: 0 0 auto;
}

.step-image {
  position: relative;
  z-index: 1;
  max-height: 350px; /* Adjust max height as needed */
  transition: transform 0.3s ease;
}

.step-image:hover {
  transform: scale(1.1); /* Scale up slightly on hover */
}

.step-content {
  position: relative;
  z-index: 1;
  margin-top: 20px;
}

.step-title {
  color: #00ace9;
  font-weight: 600;
  margin-bottom: 16px;
}

.step-description {
  font-weight: 500;
}

.share {
  position: absolute;
  width: 100px;
  height: auto;
  transform: translateY(-50%);
  bottom: 27%;
  right: 2%;
  z-index: 1;
}

.arrow-image {
  position: absolute;
  top: 35%;
  right: -6%; /* Adjust to position arrow correctly */
  transform: translateY(-50%);
  width: 60px; /* Adjust width as necessary */
  height: auto;
}

/*** END OF HOW ***/

.values {
  font-size: 20px;
  margin-bottom: 12px;
}

/* Place the container to the left */
.left {
  padding: 0px 40px 20px 0px;
  left: 0;
}

/* Place the container to the right */
.right {
  padding: 0px 0px 20px 40px;
  left: 50%;
}

/* Add arrows to the left container (pointing right) */
.left::before {
  content: " ";
  position: absolute;
  top: 18px;
  z-index: 1;
  right: 30px;
  border: medium solid #f0f3ff;
  border-width: 10px 0 10px 10px;
  border-color: transparent transparent transparent #f0f3ff;
}

/* Add arrows to the right container (pointing left) */
.right::before {
  content: " ";
  position: absolute;
  top: 18px;
  z-index: 1;
  left: 30px;
  border: medium solid #f0f3ff;
  border-width: 10px 10px 10px 0;
  border-color: transparent #f0f3ff transparent transparent;
}

/* Fix the circle for containers on the right side */
.right::after {
  left: -12px;
}

#story img {
  max-width: 243px;
  height: 280px;
}

#management img {
  width: 234px;
  height: 234px;
  border-radius: 100%;
  object-fit: cover;
  object-position: 25% 0%;
}

#management .namecard {
  height: 96px;
  width: 300px;
  display: inline-flex;
  padding: 12px;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  border-radius: 6px;
  position: relative;
  justify-content: center;
}

.animate {
  animation-duration: 0.3s;
  -webkit-animation-duration: 0.3s;
  animation-fill-mode: both;
  -webkit-animation-fill-mode: both;
}

.cta .logo {
  width: 100%;
  max-width: 204px;
  height: auto;
}

section#cta .row {
  display: flex;
  align-items: center;
  background-image: url("../../public/img/cta-bg.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 650px;
  border-radius: 24px;
  color: white;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

.cta-content {
  z-index: 1;
}

img.download-app {
  border-radius: 12px;
  height: 308px;
}

.cta-accesories {
  width: 200px;
  position: absolute;
  bottom: 8%;
  left: 6%;
  z-index: 0;
}

.cta-accesories2 {
  width: 200px;
  position: absolute;
  bottom: 7%;
  right: 4%;
  z-index: 0;
}

/* FAQ */
#faq {
  color: #00ace9;
}

.accordion-item {
  margin: 12px 0;
  border-radius: 8px !important;
}

.accordion-button {
  font-weight: 600;
  border-radius: 8px !important;
  padding: 1.25rem;
  line-height: var(--bs-body-line-height);
}
/* END OF FAQ */

/* Footer additions */
.footer-office-indent {
  margin-left: 5px;
}

.footer-address-indent {
  margin-left: 25px;
}

.footer-title-color {
  color: #0a0a0a;
}

.footer-text-color {
  color: #404040;
}

.footer-link-highlight {
}

.footer-link-highlight:hover {
  color: #404040;
}

.footer-font-size {
  font-size: 1rem;
}

.footer-font-small {
  font-size: 0.875rem;
}

.footer-nav-mb {
  margin-bottom: 2rem;
}

.footer-phone-mobile {
}

footer {
  background-image: linear-gradient(#ffffff, #00aeef);
}

footer p {
  font-size: 16px;
}

footer a:hover {
  text-decoration: underline !important;
}

.pengaduan-line-height {
  line-height: 200%;
}

.footer-custom-hr {
}

.footer-copyright-container {
  display: flex;
  align-items: center;
}

.customer-care-icon {
  width: 20px;
  height: 20px;
}

.license-logo {
  width: 100%;
  max-width: 100px;
}

.footer-license-bi {
  height: 56px;
  width: 210px;
}

.footer-license-kominfo {
  height: 56px;
  width: 56px;
}

.footer-license-pcidss {
  height: 33px;
  width: 105px;
}

.footer-license-apgi {
  height: 45px;
  width: 85px;
}

.footer-license-aspi {
  height: 32px;
  width: 100px;
}

#copyright {
  display: flex;
  flex-direction: column;
}

@keyframes slideIn {
  0% {
    transform: translateY(-0.5rem);
    opacity: 0;
  }
  100% {
    transform: translateY(0rem);
    opacity: 1;
  }
  0% {
    transform: translateY(-0.5rem);
    opacity: 0;
  }
}

@-webkit-keyframes slideIn {
  0% {
    -webkit-transform: transform;
    -webkit-opacity: 0;
  }
  100% {
    -webkit-transform: translateY(1em);
    -webkit-opacity: 1;
  }
  0% {
    -webkit-transform: translateY(0);
    -webkit-opacity: 0;
  }
}

.slideIn {
  -webkit-animation-name: slideIn;
  animation-name: slideIn;
}

section#support .row {
  display: flex;
  align-items: center;
  background: linear-gradient(
    150deg,
    hsla(204, 84%, 40%, 1) 0%,
    hsla(200, 96%, 41%, 1) 43%,
    hsla(192, 69%, 74%, 1) 100%
  );
  height: 250px;
  border-radius: 24px;
  color: white;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

#support h1 {
  font-size: 2.25rem;
  line-height: 3rem;
  letter-spacing: 0.025em;
  font-weight: 600;
}

/*************************************/
/************  DEMO PAGE  ************/
/*************************************/
#bannerDemo {
  align-items: center;
  background-image: url("../../public/img/cta-bg.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  border-radius: 24px;
  color: white;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  padding: 2.5rem;
  position: relative; /* Added to contain the absolutely positioned image */
}

#bannerDemo img#demoImage {
  max-width: 456px;
  right: 0;
  top: 65%;
  transform: translateY(-55%);
  position: absolute;
}

#bannerDemo hr {
  border: 1px solid white;
  opacity: 1;
  border-radius: 10px;
  width: 300px;
}

#bannerDemo ul {
  list-style: none;
  padding-left: 0;
}

#bannerDemo ul li {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

#bannerDemo ul li img.icon-section {
  width: 24px;
  height: 24px;
  margin-right: 10px;
}

.form-control:focus {
  color: #212529;
  background-color: #fff;
  border-color: #00ace9;
  box-shadow: none;
}

.demo-to-home {
  cursor: pointer;
}

.demo-to-home:hover {
  text-decoration: underline !important;
}

/****************************************/
/************  Layanan PAGE  ************/
/****************************************/

#prosesPengaduan {
  padding: 120px 0;
}

#prosesPengaduan h1 {
  font-size: 2.0625rem;
  line-height: 50px;
  color: #404040;
  font-weight: 600;
}

#prosesPengaduan h4 {
  font-size: 1.4375rem;
  line-height: 35px;
  color: #404040;
  font-weight: 600;
}

#prosesPengaduan p {
  font-size: 1rem;
  line-height: 24px;
  font-weight: 500;
}

#prosesPengaduan strong {
  font-size: 1.1875rem;
  line-height: 24px;
  font-weight: 600;
  margin-bottom: 6px;
  display: inline-block;
}

#prosesPengaduan ol li::marker {
  font-size: 1.1875em; /* Adjust the size as needed */
  font-weight: 600;
}

.konsumen {
  background-color: #f5f5f5;
  padding: 16px 32px;
  border-radius: 12px;
}

/*********************************************/
/*************  Repository PAGE  *************/
/*********************************************/

.text-password-container-rep {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  background-color: white;
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  border-radius: 8px;
}

.blurred-rep {
  filter: blur(15px);
  pointer-events: none;
}

.no-select {
  user-select: none;
}

.styled-input-rep {
  padding: 10px;
  margin: 10px 0;
  border: 1px solid #ccc;
  border-radius: 5px;
  width: 100%;
  box-sizing: border-box;
  font-size: 16px;
}

.styled-button-rep {
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
}

.styled-button-rep:hover {
  background-color: #0056b3;
}

.cards-container-rep {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
}

.card-rep {
  display: flex;
  flex-direction: column;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  width: 270px;
  height: 100px;
  text-align: center;
  transition: transform 0.2s;
  cursor: pointer;
}

.card-rep:hover {
  transform: scale(1.05);
}

.card-image-rep {
  width: 100%;
  height: 250px;
  object-fit: cover;
}

.card-title-rep {
  font-size: 1.125rem;
  margin: 10px 0 1px 0;
  overflow: hidden;
  position: relative;
}

.card-version-rep {
  font-size: 1.25rem;
  color: gray;
  margin-top: auto;
  margin-bottom: 10px;
}

.error-message-pw-rep {
  color: red;
  font-size: 1rem;
}

/****************************************/
/**************  Blog PAGE  *************/
/****************************************/

.directory-spacer {
}

.directory-container {
}

.directory-home-icon {
  width: 24px;
  height: 24px;
}

.directory-arrow {
  font-size: 0.875rem;
  color: #9e9e9e;
}

.directory-text {
  font-size: 0.875rem;
  color: #9e9e9e;
  cursor: pointer;
}

.directory-text-limit {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 9ch;
  display: inline-block;
  vertical-align: middle;
  padding-bottom: 3px;
}

.directory-text:hover {
  color: #00aeef;
}

.directory-active-hover {
  color: #00aeef;
}

.blog-card {
  border-radius: 6px;
  box-shadow: 20px 30px 60px 0px #0000001a;
  overflow: hidden;
  width: 280px;
  height: 390px;
  cursor: pointer;
}

.blog-card-image {
  width: 100%;
  height: 200px;
  object-fit: cover;
}

.blog-card-content {
  padding: 16px 24px;
  background-color: #ffffff;
  height: 100%;
}

.blog-card-title {
  font-size: 1rem;
  font-weight: 700;
  color: #0a0a0a;
  margin-top: -8px;
  margin-bottom: 12px;
  line-height: 24px;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out;
}

.blog-card:hover .blog-card-title {
  color: #00aeef;
}

.blog-card-details {
  font-size: 0.875rem;
  font-weight: 400;
  color: #757575;
}

.blog-card-gallery {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  gap: 10px;
  row-gap: 50px;
}

.pagination-container {
  display: flex;
  justify-content: flex-end;
  list-style: none; /* Remove default list styling */
  padding: 0;
  margin-top: 75px;
}

.pagination-container li {
  margin: 0 0.375rem; /* Add spacing between pagination items */
  font-size: 0.875rem;
  font-weight: 400;
  border: 1px solid #f5f5f5;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background-color 0.2s ease-in-out, border-color 0.2s;
}

/* 
.pagination-container li:hover {
  border: 1px solid #00aeef;
  background-color: #00aeef;
  font-weight: bold;
  color: #ffffff;
}

.pagination-container li:hover a {
  color: #ffffff;
} */

.pagination-container .pagination-active {
  border: 1px solid #00aeef;
  background-color: #00aeef;
  font-weight: bold;
  color: #ffffff;
}

.pagination-item a.pagination-link,
.pagination-previous a.pagination-previous-link,
.pagination-next a.pagination-next-link {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  text-decoration: none;
  color: inherit;
}

.sticky-sidebar {
  position: -webkit-sticky; /* for Safari */
  position: sticky;
  top: 120px;
}

.article-sidebar-title {
  font-size: 0.875rem;
  font-weight: 500;
  color: #0a0a0a;
}

.article-sidebar-divider {
  border: none;
  border-top: 2px solid #9e9e9e;
  margin-top: 5px;
  margin-right: 50px;
}

.article-sidebar-items {
  font-size: 0.875rem;
  font-weight: 400;
  color: #9e9e9e;
  max-width: 85%;
}

.article-sidebar-items:hover {
  color: #00aeef;
  text-decoration: underline;
}

.article-sidebar-items.active {
  color: #00aeef;
  text-decoration: underline;
}

.article-content-padding {
  padding-bottom: 2rem;
}

.article-title {
  font-size: 1.75rem;
  font-weight: 800;
  color: #0a0a0a;
  line-height: 48px;
}

.article-date {
  font-size: 0.875rem;
  font-weight: 400;
  color: #9e9e9e;
  margin-top: 10px;
}

.article-main-img {
  width: 100%;
  border-radius: 20px;
  box-shadow: 20px 30px 60px 0px #0000001a;
}

.article-standard-img {
  width: 100%;
  margin: 1.5rem 0;
  border-radius: 10px;
  box-shadow: 20px 30px 60px 0px #0000001a;
}

.article-subheading {
  font-size: 1.3125rem;
  font-weight: 500;
  color: #0a0a0a;
  line-height: 36px;
}

.article-text {
  font-size: 0.875rem;
  font-weight: 400;
  color: #0a0a0a;
  line-height: 24px;
}

.article-list-title {
  font-size: 0.875rem;
  /* font-weight: 700; */
  color: #0a0a0a;
  line-height: 24px;
  list-style-type: decimal;
  padding-left: 0px;
}

.article-list-title-counter {
}

.article-list-title li {
  margin-left: 0;
  padding-left: 0;
  display: list-item;
  list-style-position: outside;
}

.article-list-text {
  font-size: 0.875rem;
  font-weight: 400;
  color: #0a0a0a;
  line-height: 24px;
  padding-left: 22px;
}

.article-sharing-title {
  font-size: 0.875rem;
  font-weight: 500;
  color: #0a0a0a;
}

.article-sharing-backing {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  background-color: #ededed;
  border-radius: 50%;
  cursor: pointer;
  transition: background-color 0.15s ease-in-out;
}

.article-sharing-backing:hover {
  background-color: #def3ff;
}

.article-sharing-icon {
  width: 20px;
  height: 20px;
  transition: filter 0.15s ease-in-out;
}

.article-sharing-backing:hover .article-sharing-icon {
  filter: invert(56%) sepia(85%) saturate(459%) hue-rotate(172deg)
    brightness(98%) contrast(101%);
}

.article-sharing-container-mobile {
  display: none;
}

.article-sharing-title-mobile {
  font-size: 1.5rem;
  font-weight: 500;
  color: #0a0a0a;
}

.article-suggestions-padding {
  padding-bottom: 100px;
}

.article-suggestions-divider {
  border: none;
  border-top: 2px solid #9e9e9e;
}

.article-suggestions-title {
  font-size: 1.3125rem;
  font-weight: 500;
  color: #0a0a0a;
  line-height: 36px;
}

.article-suggestions-spacer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.article-suggestions-more {
  cursor: pointer;
}

.article-suggestions-more-text {
  font-size: 0.875rem;
  font-weight: 500;
  color: #00aeef;
}

.article-suggestions-arrow {
  width: 16px;
  height: 16px;
  margin-left: 0.25rem;
}

@media screen and (max-width: 1320px) {
  header.masthead {
    height: 690px;
  }

  #mainNav {
    margin: 12px;
    border-radius: 12px;
  }

  #mainNav .navbar-nav .nav-item .nav-link {
    padding: 12px 12px;
  }

  /* Fitur */
  #fiturOjire h1 {
    font-size: 3.25rem;
    line-height: 3.75rem;
  }

  #fiturOjire .card h5 {
    font-size: 1.5rem;
    line-height: 1.75rem;
  }

  #fiturOjire .card p {
    font-size: 1.25rem;
    line-height: 1.75rem;
  }

  #fiturOjire .card-body {
    padding: 16px;
  }

  #fiturOjire .card h5 {
    margin-bottom: 6px;
  }

  .card-brand h1 {
    font-size: 4.5rem !important;
  }

  /* End Fitur */

  /* Blog */

  .blog-card {
    width: 270px;
  }

  .blog-card-gallery {
    grid-template-columns: repeat(auto-fill, minmax(270px, 1fr));
  }

  /* End Blog */
}

@media screen and (max-width: 1200px) {
  .arrow-image {
    display: none;
  }

  .share {
    bottom: 35%;
  }

  #fiturOjire .card h5 {
    font-size: 1.25rem;
    line-height: 1.5rem;
  }

  #fiturOjire .card p {
    font-size: 1.125rem;
    line-height: 1.5rem;
  }
}

/* Media queries - Responsive timeline on screens less than 600px wide */
@media screen and (max-width: 1025px) {
  html {
    font-size: 90%;
  }

  h6 {
    font-size: 16px;
  }

  #mainNav {
    margin: 12px;
    border-radius: 12px;
  }

  #mainNav .navbar-nav .nav-item .nav-link {
    padding: 12px 0;
  }

  header.masthead {
    height: 550px;
    background-position: center;
    min-height: 550px;
  }

  .hero-text h1 {
    font-size: 3rem;
    line-height: 4rem;
  }

  .hero-text p {
    font-size: 1.5rem;
    line-height: 2rem;
    margin: 24px 0px !important;
  }

  #heroContent {
    margin-top: 35px;
  }

  .hero-images {
    width: 110%;
    right: -5%;
    bottom: -10px;
    transform: translateY(50%);
    z-index: 10;
  }

  /* Fitur */
  #fiturOjire h1 {
    font-size: 3.25rem;
    line-height: 3.75rem;
  }

  #fiturOjire .card h5 {
    font-size: 1.5rem;
    line-height: 1.75rem;
  }

  #fiturOjire .card p {
    font-size: 1.25rem;
    line-height: 1.75rem;
  }

  #fiturOjire .card-body {
    padding: 16px;
  }

  #fiturOjire .card h5 {
    margin-bottom: 6px;
  }

  .card-brand h1 {
    font-size: 4.5rem !important;
  }

  /* End Fitur */

  .why-image img {
    height: fit-content;
  }

  .arrow-image {
    display: none;
  }

  .feature-accesories2 {
    height: 300px;
    top: -5%;
  }

  .cta-accesories2 {
    bottom: 11%;
    right: 4%;
  }

  .cta-accesories {
    width: 180px;
    position: absolute;
    bottom: 8%;
    left: 3%;
    z-index: 0;
  }

  #prosesPengaduan {
    padding: 90px 0;
  }

  /* Blog */

  .directory-text,
  .directory-arrow {
    font-size: 1.25rem;
  }

  .directory-spacer {
    margin-top: 2vh;
    margin-left: 1vw;
  }

  .directory-text-limit {
    padding-bottom: 5px;
  }

  .directory-container {
    margin-top: 15px;
    margin-left: 15px;
  }

  .blog-card {
    width: 220px;
    height: 300px;
  }

  .blog-card-gallery {
    grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));
    margin-left: 1vw;
  }

  .blog-card-image {
    height: 150px;
  }

  .blog-card-content {
    padding: 12px 18px;
  }

  .blog-card-title {
    line-height: 20px;
    margin-top: -10px;
    margin-bottom: 4px;
    font-size: 1rem;
  }

  .blog-card-details {
    font-size: 0.75rem;
  }

  .article-content-padding {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  .article-suggestions-divider {
    margin-left: 1.5rem;
    margin-right: 1.5rem;
  }

  .article-suggestions-spacer {
    margin-left: 1.5rem;
    margin-right: 1.5rem;
  }

  /* End blog */
}

@media screen and (max-width: 990px) {
  header.masthead {
    background-position: center;
    min-height: 950px;
  }

  header.masthead::before {
    height: 980px;
    background: url("../../public/img/hero-bg-mobile.jpg");
    background-position: center;
    clip-path: polygon(0 0, 100% 0, 100% 30%, 50% 100%, 0 100%);
  }

  .hero-text {
    padding: 0px 20px;
  }

  .hero-text h1 {
    font-size: 4rem;
    line-height: 5rem;
  }

  .hero-text p {
    font-size: 2rem;
    line-height: 2.5rem;
    margin: 24px 0px !important;
  }

  #heroContent {
    margin-top: 35px;
    height: 50%;
  }

  .hero-images {
    width: 70%;
    right: 10%;
    transform: translateY(85%);
  }

  .hero-accesories {
    display: none;
  }
  /* Fitur */

  #fiturOjire .card h5 {
    font-size: 1.2rem;
    line-height: 1.5rem;
  }

  #fiturOjire .card p {
    font-size: 1rem;
    line-height: 1.35rem;
  }

  #fiturOjire .card-body {
    padding: 12px;
  }

  #fiturOjire .card h5 {
    margin-bottom: 6px;
  }

  .card-brand h1 {
    font-size: 3.75rem !important;
  }

  .card-brand img.card-img-top {
    width: 150px !important;
  }

  /* Blog */
  .directory-spacer {
    margin-top: 2vh;
    margin-left: 3vw;
  }

  .directory-container {
    margin-left: 25px;
  }

  .blog-card-gallery {
    margin-left: 3vw;
  }
  /* End blog */
}

@media screen and (max-width: 767.98px) {
  h1 {
    font-size: 2.5rem;
    line-height: 3rem;
  }

  header.masthead {
    background-position: center;
    min-height: 950px;
  }

  header.masthead::before {
    height: 980px;
    background: url("../../public/img/hero-bg-mobile.jpg");
    background-position: center;
    clip-path: polygon(0 0, 100% 0, 100% 60%, 50% 100%, 0 100%);
  }

  #mainNav {
    margin: 0px;
    border-radius: 0px;
  }

  .hero-text {
    padding: 0px 20px;
  }

  .hero-text h1 {
    font-size: 4rem;
    line-height: 5rem;
  }

  .hero-text p {
    font-size: 2rem;
    line-height: 2.5rem;
    margin: 24px 0px !important;
  }

  #heroContent {
    margin-top: 35px;
    height: 50%;
  }

  .hero-images {
    width: 80%;
    right: 10%;
    transform: translateY(110%);
  }

  .hero-accesories {
    display: none;
  }

  .feature-accesories {
    width: 150px;
    top: -6%;
    left: -7%;
  }

  .feature-accesories2 {
    top: -9.5%;
    right: -5%;
  }

  /* Fitur */
  #fiturOjire h1 {
    font-size: 2.5rem;
    line-height: 3rem;
  }

  #fiturOjire .card {
    align-items: start;
  }

  #fiturOjire .card h5 {
    font-size: 2rem;
    line-height: 3rem;
  }

  #fiturOjire .card p {
    font-size: 1.5rem;
    line-height: 2rem;
  }

  #fiturOjire .card-body {
    padding: 20px;
  }

  #fiturOjire .card h5 {
    margin-bottom: 6px;
  }

  #fiturOjire .card-brand {
    max-height: 250px;
    align-items: center;
  }

  #fiturOjire .card-brand h1 {
    font-size: 5rem !important;
  }

  .step-title {
    font-size: 2rem;
    line-height: 2.25rem;
  }

  .step-description {
    font-size: 1.5rem;
    line-height: 2.25rem;
    padding: 0px 12px;
  }

  .card-brand .card-img-top {
    position: static;
    transform: none;
    margin-bottom: 24px;
  }

  .card-brand .card-title {
    margin-bottom: 36px;
  }

  .card-image {
    position: absolute;
    width: 350px;
    left: 49.9%;
    transform: translateX(-50%);
    bottom: 0;
  }

  .card-main {
    width: 100%;
    border-radius: 24px;
    margin-bottom: 20px;
  }

  /* END OF FEATURE */

  /* HOW */
  .share {
    width: 80px;
    bottom: 40%;
  }

  .step-title {
    font-size: 1.5rem;
    line-height: 1.75rem;
    margin-bottom: 12px;
  }

  .step-description {
    font-size: 1.25rem;
    line-height: 1.75rem;
  }
  /* END OF HOW */

  /* Partners */
  .partners-img {
    max-width: 1020px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
  }

  .cimb-logo-mobile {
    width: 250px;
    height: 40px;
  }

  .bri-logo-mobile {
    width: 160px;
    height: 40px;
  }

  .bni-logo-mobile {
    width: 130px;
    height: 40px;
  }
  /* End Partners */

  #cta {
    padding: 8rem 20px;
  }
  .cta-accesories,
  .cta-accesories2 {
    display: none;
  }

  #support {
    padding: 8rem 20px;
  }

  /* Footer */
  .license-logo {
    width: 100%;
    max-width: 80px;
  }

  #licensed {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .footer-address-center {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .footer-office-indent {
    margin-left: 0px;
  }

  .footer-address-indent {
    margin-left: 0px;
  }

  .footer-phone-mobile {
    justify-content: center;
  }

  /* Blog */
  .directory-text,
  .directory-arrow {
    font-size: 1.35rem;
  }

  .directory-spacer {
    margin-top: 1vh;
  }

  .directory-container {
    margin-top: 5px;
    margin-left: 10px;
    margin-bottom: 50px;
  }

  .blog-card {
    width: 180px;
  }

  .blog-card-gallery {
    grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
  }

  .blog-card-details {
    font-size: 0.85rem;
  }

  .sticky-sidebar {
    display: none !important;
  }

  .article-content-padding {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }

  .article-suggestions-divider {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }

  .article-suggestions-spacer {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }

  .article-title {
    font-size: 1.875rem;
    line-height: 48px;
  }

  .article-date {
    font-size: 1.3125rem;
    margin-top: 15px;
  }

  .article-main-img {
    border-radius: 10px;
  }

  .article-subheading {
    font-size: 1.5rem;
    line-height: 36px;
    font-weight: 600;
    margin-top: 2.5rem;
    margin-bottom: 1.5rem;
  }

  .article-text {
    font-size: 1.3125rem;
    line-height: 24px;
  }

  .article-list-title {
    font-size: 1.3125rem;
    line-height: 24px;
    margin-left: 7px;
  }

  .article-list-title-counter {
    margin-left: -1px;
  }

  .article-list-text {
    font-size: 1.3125rem;
    line-height: 24px;
  }

  .article-sharing-container-mobile {
    display: flex;
    align-items: center;
    margin-top: 15px;
    margin-bottom: 25px;
  }
  /* End blog */
}

/* Media queries - Responsive timeline on screens less than 600px wide */
@media screen and (max-width: 426px) {
  html {
    font-size: 70%;
  }

  h1 {
    font-size: 2.75rem;
    line-height: 3.75rem;
  }

  h6 {
    font-size: 16px;
  }

  #mainNav {
    margin: 0px;
    border-radius: 0px;
  }

  .floating {
    bottom: 7rem;
    right: 1.5rem;
  }

  .floating-blog {
    bottom: 3rem;
    right: 1.5rem;
  }

  .scroll-to-top-btn {
    width: 5.5rem;
    height: 5.5rem;
  }

  .btn-whatsapp {
    font-size: 14px !important;
    padding: 10px 12px;
  }

  .bottom-bar-fade-in {
    position: fixed;
    bottom: 0rem;
    padding: 0.5rem 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 10;
    background: white;
    width: 100%;
    animation: fade-in 0.3s;
    opacity: 1;
  }

  .bottom-bar-fade-out {
    position: fixed;
    pointer-events: none;
    bottom: 0rem;
    padding: 0.5rem 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 10;
    background: white;
    width: 100%;
    animation: fade-out 0.3s;
    opacity: 0;
  }

  .bottom-bar .btn {
    padding: 12px 12px;
    width: 170px;
    box-shadow: 0px -8px 16px rgba(0, 0, 0, 0.08);
  }

  .scroll-to-top-btn {
    margin-bottom: 0;
    opacity: 1;
  }

  .navbar-collapse .d-flex {
    display: none !important;
  }

  .toggle-switch {
    margin-bottom: 12px;
  }

  header.masthead {
    min-height: 800px;
  }

  header.masthead::before {
    height: 800px;
    background: url("../../public/img/hero-bg-mobile.jpg");
    background-position: center;
    clip-path: polygon(0 0, 100% 0, 100% 60%, 50% 100%, 0 100%);
  }

  #heroContent {
    margin-top: 0px;
    text-align: center;
    height: 80%;
  }

  .hero-text {
    margin-bottom: 100px;
  }

  #heroContent .btn {
    display: block;
    width: 50%;
    margin-bottom: 12px;
    margin-left: auto;
    margin-right: auto;
  }

  #heroContent .btn-primary {
    background-color: #fff;
    color: #0088be;
  }

  h1.hero-header {
    font-size: 3.5rem;
    margin: 12px 0px 12px;
  }

  .hero-text p {
    font-size: 1.5rem;
  }

  .hero-images {
    width: 100%;
    right: 0%;
    bottom: -40px;
    transform: translateY(50%);
  }

  .hero-accesories {
    left: -80%;
  }

  .hero-accesories2 {
    width: 125px;
    bottom: -40px;
    right: 0;
  }

  /* WHY */
  .why-bg {
    bottom: 550px;
  }

  #whyOjire .card {
    max-width: 300px;
    margin: auto;
  }

  /* END OF WHY */

  /* FEATURE */
  .feature-accesories {
    width: 150px;
    top: -8%;
    left: -15%;
  }
  .feature-accesories2 {
    width: 120px;
    top: 5.5%;
    right: 1%;
  }

  #fiturOjire .card {
    align-items: start;
  }

  #fiturOjire .card h5 {
    font-size: 2.5rem;
    line-height: 3.5rem;
  }

  #fiturOjire .card p {
    font-size: 1.5rem;
    line-height: 2.5rem;
  }

  #fiturOjire .card-body {
    padding: 20px;
  }

  #fiturOjire .card h5 {
    margin-bottom: 6px;
  }

  #fiturOjire .card-brand {
    max-height: 250px;
    align-items: center;
  }

  #fiturOjire .card-brand h1 {
    font-size: 5rem !important;
  }

  .step-title {
    font-size: 2rem;
    line-height: 2.25rem;
  }

  .step-description {
    font-size: 1.5rem;
    line-height: 2.25rem;
    padding: 0px 12px;
  }

  .card-brand .card-img-top {
    position: static;
    transform: none;
    margin-bottom: 24px;
  }

  .card-brand .card-title {
    margin-bottom: 36px;
  }

  .card-image {
    position: absolute;
    width: 350px;
    left: 49.9%;
    transform: translateX(-50%);
    bottom: 0;
  }

  .card-main {
    width: 100%;
    border-radius: 24px;
    margin-bottom: 20px;
  }

  /* HOW */
  .multi-bg {
    background: url(../../public/img/multi-bg-mb.jpg) no-repeat center center;
    background-size: cover;
  }

  .workflow-step {
    margin-bottom: 70px;
  }

  .how-content .text {
    text-align: center;
    margin-bottom: 36px;
  }

  .how-image img.active-image {
    display: none;
  }

  .share {
    right: 6%;
  }

  /* END OF HOW */

  /* CTA */
  #cta,
  #support {
    padding: 8rem 16px;
  }

  #cta span {
    font-size: 2.5rem;
  }

  .cta-accesories,
  .cta-accesories2 {
    display: none;
  }

  /* END OF CTA */

  /* FAQ */
  #faq .accordion {
    width: 90% !important;
  }

  .accordion-button,
  .accordion-body {
    font-size: 16px;
    line-height: normal;
  }

  .faq-mobile-pad {
    padding-left: 12%;
  }
  /* END OF FAQ */

  /* FOOTER */
  footer {
    font-size: 16px;
    padding-bottom: 80px;
  }

  footer p {
    margin-bottom: 20px;
  }

  #licensed {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .footer-font-size {
    font-size: 1.5rem;
  }

  .footer-font-small {
    font-size: 1.5rem;
  }

  .footer-address-center {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  /* END OF FOOTER */

  /* DEMO */
  #bannerDemo {
    height: 600px;
  }

  #demo {
    font-size: 16px;
  }

  #bannerDemo img#demoImage {
    max-width: 356px;
    right: 50%;
    transform: translateX(50%);
    top: 350px;
    position: absolute;
  }

  #bannerDemo .icon-section {
    margin-bottom: auto;
  }

  .form-control {
    font-size: 16px;
    padding: 8px 12px;
    border-radius: 6px;
  }

  #contactForm > div.mb-3 {
    margin-bottom: 24px !important;
  }
  /* END OF DEMO */

  #prosesPengaduan {
    padding: 64px 0;
  }

  /* Blog */
  .directory-text,
  .directory-arrow {
    font-size: 1.5rem;
  }

  .blog-card {
    width: 165px;
    height: 260px;
  }

  .blog-card-gallery {
    grid-template-columns: repeat(auto-fill, minmax(165px, 1fr));
  }

  .blog-card-image {
    height: 130px;
  }

  .blog-card-title {
    line-height: 16px;
    margin-top: -8px;
    margin-bottom: 6px;
    font-size: 1.125rem;
  }

  .blog-card-details {
    font-size: 0.9375rem;
  }

  .article-title {
    line-height: 30px;
  }

  .article-subheading {
    line-height: 24px;
  }

  .article-text {
    line-height: 21px;
  }

  .article-list-title {
    font-size: 1.3125rem;
    line-height: 24px;
  }

  .article-list-text {
    line-height: 21px;
  }
  /* End blog */
}

@media screen and (max-width: 374px) {
  .blog-card {
    width: 280px;
    height: 300px;
  }

  .blog-card-gallery {
    grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
    margin-left: 8%;
  }

  .blog-card-image {
    height: 150px;
  }

  .blog-card-title {
    line-height: 20px;
    margin-top: -6px;
    margin-bottom: 8px;
    font-size: 1.25rem;
  }

  .blog-card-details {
    font-size: 1rem;
  }

  .blog-card-content {
    padding: 16px 24px;
  }
}

@media screen and (max-width: 340px) {
  .blog-card-gallery {
    margin-left: 4%;
  }
}
